import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@linaria/react';
import React from 'react';
const Container = styled.div `
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`;
export default function ElementorButton({ children, ...params }) {
    return (_jsx(Container, { className: "elementor-button-wrapper", children: _jsx("button", { className: "elementor-button elementor-button-default", type: "button", ...params, children: children }) }));
}
