import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@linaria/react';
import { CALYPSO, CALYPSO_LIGHT, CALYPSO_MEDIUM, OBSIDIAN, } from '../UIComponents/colors';
import UISpinner from '../UIComponents/UISpinner';
import LoadState from '../enums/loadState';
const Container = styled.div `
  color: ${OBSIDIAN};
  font-family: 'Lexend Deca', Helvetica, Arial, sans-serif;
  font-size: 14px;
  position: relative;
`;
const ControlContainer = styled.div `
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: ${props => (props.focused ? '0' : '1px')};
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  box-shadow: ${props => props.focused ? `0 0 0 2px ${CALYPSO_MEDIUM}` : 'none'};
  &:hover {
    border-color: hsl(0, 0%, 70%);
  }
`;
const ValueContainer = styled.div `
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  padding: 2px 8px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
`;
const Placeholder = styled.div `
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 16px;
`;
const SingleValue = styled.div `
  color: hsl(0, 0%, 20%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
  box-sizing: border-box;
`;
const IndicatorContainer = styled.div `
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
`;
const DropdownIndicator = styled.div `
  border-top: 8px solid ${CALYPSO};
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  width: 0px;
  height: 0px;
  margin: 10px;
`;
const InputContainer = styled.div `
  margin: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  visibility: visible;
  color: hsl(0, 0%, 20%);
  box-sizing: border-box;
`;
const Input = styled.input `
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0) none repeat scroll 0px center;
  border: 0px none;
  font-size: inherit;
  opacity: 1;
  outline: currentcolor none 0px;
  padding: 0px;
  color: inherit;
  font-family: inherit;
`;
const InputShadow = styled.div `
  position: absolute;
  opacity: 0;
  font-size: inherit;
`;
const MenuContainer = styled.div `
  position: absolute;
  top: 100%;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 9999;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  width: 100%;
`;
const MenuList = styled.div `
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: relative;
`;
const MenuGroup = styled.div `
  padding-bottom: 8px;
  padding-top: 8px;
`;
const MenuGroupHeader = styled.div `
  color: #999;
  cursor: default;
  font-size: 75%;
  font-weight: 500;
  margin-bottom: 0.25em;
  text-transform: uppercase;
  padding-left: 12px;
  padding-left: 12px;
`;
const MenuItem = styled.div `
  display: block;
  background-color: ${props => props.selected ? CALYPSO_MEDIUM : 'transparent'};
  color: ${props => (props.selected ? '#fff' : 'inherit')};
  cursor: default;
  font-size: inherit;
  width: 100%;
  padding: 8px 12px;
  &:hover {
    background-color: ${props => props.selected ? CALYPSO_MEDIUM : CALYPSO_LIGHT};
  }
`;
export default function AsyncSelect({ placeholder, value, loadOptions, onChange, defaultOptions, }) {
    const inputEl = useRef(null);
    const inputShadowEl = useRef(null);
    const [isFocused, setFocus] = useState(false);
    const [loadState, setLoadState] = useState(LoadState.NotLoaded);
    const [localValue, setLocalValue] = useState('');
    const [options, setOptions] = useState(defaultOptions);
    const inputSize = `${inputShadowEl.current ? inputShadowEl.current.clientWidth + 10 : 2}px`;
    useEffect(() => {
        if (loadOptions && loadState === LoadState.NotLoaded) {
            loadOptions('', (result) => {
                setOptions(result);
                setLoadState(LoadState.Idle);
            });
        }
    }, [loadOptions, loadState]);
    const renderItems = (items = [], parentKey) => {
        return items.map((item, index) => {
            if (item.options) {
                return (_jsxs(MenuGroup, { children: [_jsx(MenuGroupHeader, { id: `${index}-heading`, children: item.label }), _jsx("div", { children: renderItems(item.options, index) })] }, `async-select-item-${index}`));
            }
            else {
                const key = `async-select-item-${parentKey !== undefined ? `${parentKey}-${index}` : index}`;
                return (_jsx(MenuItem, { id: key, selected: value && item.value === value.value, onClick: () => {
                        onChange(item);
                        setFocus(false);
                    }, children: item.label }, key));
            }
        });
    };
    return (_jsxs(Container, { children: [_jsxs(ControlContainer, { id: "leadin-async-selector", focused: isFocused, onClick: () => {
                    if (isFocused) {
                        if (inputEl.current) {
                            inputEl.current.blur();
                        }
                        setFocus(false);
                        setLocalValue('');
                    }
                    else {
                        if (inputEl.current) {
                            inputEl.current.focus();
                        }
                        setFocus(true);
                    }
                }, children: [_jsxs(ValueContainer, { children: [localValue === '' &&
                                (!value ? (_jsx(Placeholder, { children: placeholder })) : (_jsx(SingleValue, { children: value.label }))), _jsxs(InputContainer, { children: [_jsx(Input, { ref: inputEl, onFocus: () => {
                                            setFocus(true);
                                        }, onChange: e => {
                                            setLocalValue(e.target.value);
                                            setLoadState(LoadState.Loading);
                                            loadOptions &&
                                                loadOptions(e.target.value, (result) => {
                                                    setOptions(result);
                                                    setLoadState(LoadState.Idle);
                                                });
                                        }, value: localValue, width: inputSize, id: "asycn-select-input" }), _jsx(InputShadow, { ref: inputShadowEl, children: localValue })] })] }), _jsxs(IndicatorContainer, { children: [loadState === LoadState.Loading && _jsx(UISpinner, {}), _jsx(DropdownIndicator, {})] })] }), isFocused && (_jsx(MenuContainer, { children: _jsx(MenuList, { children: renderItems(options) }) }))] }));
}
